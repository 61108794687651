<template>
  <b-container
    id="kanban"
    fluid
  >
    <b-breadcrumb
      :items="breadcrumb"
    />

    <!--    Cabeçalho -->
    <b-row>
      <b-col
        v-if="['success', 'mentoring'].includes(selectedRole.role)"
        cols="2"
        class="d-flex align-items-center"
      >
        <div>
          <b-form-checkbox
            v-model="showCalendar"
            class="mr-1"
            variant="secondary"
            switch
          >
            <span
              title="Mostrar Calendário"
            >Calendário</span>
          </b-form-checkbox>
        </div>
      </b-col>

      <b-col
        :cols="['sales', 'mentoring'].includes(selectedRole.role) ? 10 : 12"
        class="d-flex justify-content-end mb-2"
      >
        <div
          v-if="selectedRole.role === 'success'"
          class="mr-2"
        >
          <b-button
            class="mr-1"
            variant="secondary"
            :disabled="showSuccessKanban"
            @click="toggleSuccessSales('kanban')"
          >
            <span
              title="Alterar para Kanban"
            >Kanban</span>
          </b-button>

          <b-button
            class="mr-1"
            variant="secondary"
            :disabled="showSuccessCerc"
            @click="toggleSuccessSales('cerc')"
          >
            <span
              title="Alterar para CERC"
            >CERC</span>
          </b-button>
        </div>

        <div
          v-if="selectedRole.role === 'sales'"
          class="mr-2"
        >
          <b-button
            class="mr-1"
            variant="secondary"
            :disabled="showSalesKanban"
            @click="toggleKanbanSales('kanban')"
          >
            <span
              title="Alterar para Kanban"
            >Kanban</span>
          </b-button>

          <b-button
            class="mr-1"
            variant="secondary"
            :disabled="showSalesPipeline"
            @click="toggleKanbanSales('pipeline')"
          >
            <span
              title="Alterar para Pipeline"
            >Pipeline</span>
          </b-button>

          <b-button
            variant="secondary"
            :disabled="showSalesCerc"
            @click="toggleKanbanSales('cerc')"
          >
            <span
              title="Alterar para Pipeline"
            >CERC</span>
          </b-button>
        </div>

        <div
          v-if="selectedRole.role === 'mentoring'"
          class="mr-2"
        >
          <b-button
            class="mr-1"
            variant="secondary"
            :disabled="showMentoringKanban"
            @click="toggleMentoring('kanban')"
          >
            <span
              title="Alterar para Mentoria"
            >Mentoria</span>
          </b-button>

          <b-button
            class="mr-1"
            variant="secondary"
            :disabled="showMentoringSuccess"
            @click="toggleMentoring('success')"
          >
            <span
              title="Alterar para Sucesso"
            >Sucesso</span>
          </b-button>

          <b-button
            variant="secondary"
            :disabled="showMentoringCerc"
            @click="toggleMentoring('cerc')"
          >
            <span
              title="Alterar para Sucesso"
            >Cerc</span>
          </b-button>
        </div>

        <div
          v-if="selectedRole.role === 'service_qualification'"
          class="mr-2"
        >
          <b-button
            v-if="userData.permission_type === 'root'"
            class="mr-1"
            variant="secondary"
            :disabled="showServiceQualificationSuccessAeQ"
            @click="toggleServiceQualification('a&q')"
          >
            <span
              title="Mostrar A&Q"
            >A&Q</span>
          </b-button>

          <b-button
            v-if="showButtonVip"
            variant="secondary"
            class="mr-1"
            :disabled="showServiceQualificationSuccessCanalVip"
            @click="toggleServiceQualification('canal-vip')"
          >
            <span
              title="Mostrar Canal VIP"
            >Canal VIP</span>
          </b-button>

          <b-button
            variant="secondary"
            :disabled="showServiceQualificationCerc"
            @click="toggleServiceQualification('cerc')"
          >
            <span
              title="Mostrar CERC"
            >CERC</span>
          </b-button>
        </div>

        <div class="mr-1">
          <b-button
            v-b-toggle.kanban-filters
            variant="secondary"
          >
            Filtros
          </b-button>
        </div>

        <div class="mr-1">
          <b-form-select
            v-if="$can('list_all_managers', 'Kanban')"
            id="role"
            v-model="selectedRole"
            :options="roles"
            :disabled="isLoading"
            @change="changeRole()"
            @focus.native="sidebarIsOpen = true"
            @blur.native="sidebarIsOpen = false"
          />
        </div>

        <div class="d-flex align-items-center">
          <b-button
            size="sm"
            variant="primary"
            class="time-to-refresh"
            @click="getCards"
          >
            <span
              v-if="pauseReload"
              class="paused"
            >
              ||
            </span>
            <span
              v-else
              class="mr-1"
            >{{ timeToRefresh }}</span>
            <b-spinner
              v-if="isLoading"
              small
            />
            <feather-icon
              v-else
              icon="RotateCwIcon"
              size="15"
            />
          </b-button>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-collapse
          id="kanban-filters"
          class="mt-2"
        >
          <b-row style="flex-flow: column wrap; height: 880px;">
            <!-- Vendas: Origem do lead -->
            <b-col
              v-if="selectedRole.role === 'sales'"
              cols="4"
            >
              <b-card>
                <b-card-title
                  class="my-1"
                >
                  Origem do lead
                </b-card-title>
                <b-card-body>
                  <b-form-checkbox
                    :checked="Object.values(filtersSalesCards.sales_lead_origin).every(e => e)"
                    switch
                    @change="toggleAll(filtersSalesCards, 'sales_lead_origin')"
                  >
                    Todos
                  </b-form-checkbox>
                </b-card-body>
                <b-card-body
                  v-for="option in salesLeadOriginOptions"
                  :key="option.value"
                >
                  <b-form-checkbox
                    v-model="filtersSalesCards.sales_lead_origin[option.value]"
                    switch
                  >
                    <sales-lead-origin
                      :card="{ sales_lead_origin: option.value }"
                    />
                  </b-form-checkbox>
                </b-card-body>
              </b-card>
            </b-col>
            <!-- Vendas: Qualificação do Lead -->
            <b-col
              v-if="selectedRole.role === 'sales'"
              cols="4"
            >
              <b-card>
                <b-card-title
                  class="my-1"
                >
                  Qualificação do lead
                </b-card-title>
                <b-card-body>
                  <b-form-checkbox
                    :checked="Object.values(filtersSalesCards.sales_lead_qualification).every(e => e)"
                    switch
                    @change="toggleAll(filtersSalesCards, 'sales_lead_qualification')"
                  >
                    Todos
                  </b-form-checkbox>
                </b-card-body>
                <b-card-body>
                  <b-form-checkbox
                    v-model="filtersSalesCards.sales_lead_qualification['null']"
                    switch
                  >
                    Não qualificado
                  </b-form-checkbox>
                </b-card-body>
                <b-card-body
                  v-for="rating in 3"
                  :key="rating"
                >
                  <b-form-checkbox
                    v-model="filtersSalesCards.sales_lead_qualification[rating]"
                    switch
                  >
                    <feather-icon
                      v-for="star in rating"
                      :key="star"
                      icon="StarIcon"
                      fill="yellow"
                      stroke="gold"
                    />
                  </b-form-checkbox>
                </b-card-body>
              </b-card>
            </b-col>
            <!-- Sucesso: Origem -->
            <b-col
              v-if="selectedRole.role === 'success'"
              cols="4"
            >
              <b-card>
                <b-card-title
                  class="my-1"
                >
                  Origem
                </b-card-title>
                <b-card-body>
                  <b-form-checkbox
                    :checked="Object.values(filtersSuccessCards.success_subscriber_origin).every(e => e)"
                    switch
                    @change="toggleAll(filtersSuccessCards, 'success_subscriber_origin')"
                  >
                    Todos
                  </b-form-checkbox>
                </b-card-body>
                <b-card-body
                  v-for="option in roleSuccessSubscriberOrigins"
                  :key="option.value"
                >
                  <b-form-checkbox
                    v-model="filtersSuccessCards.success_subscriber_origin[option.value]"
                    switch
                  >
                    <success-subscriber-origin
                      :card="{ success_subscriber_origin: option.value }"
                    />
                  </b-form-checkbox>
                </b-card-body>
              </b-card>
            </b-col>
            <!-- Tipo -->
            <b-col cols="4">
              <b-card>
                <b-card-title
                  class="my-1"
                >
                  Tipo
                </b-card-title>
                <b-card-body>
                  <b-form-checkbox
                    :checked="Object.values(filterUserTypes).every(e => e)"
                    switch
                    @change="toggleAll('filterUserTypes')"
                  >
                    Todos
                  </b-form-checkbox>
                </b-card-body>
                <b-card-body
                  v-for="option in userTypes"
                  :key="option.value"
                >
                  <b-form-checkbox
                    v-model="filterUserTypes[option.value]"
                    switch
                  >
                    <b-badge
                      pill
                      :variant="option.value === 'company' ? 'light-warning' : 'light-info'"
                    >
                      {{ $t(`users.columns.type.${option.value}`) }}
                    </b-badge>
                  </b-form-checkbox>
                </b-card-body>
              </b-card>
            </b-col>
            <!-- Vendas: Ordenação -->
            <b-col
              v-if="selectedRole.role === 'sales'"
              cols="4"
            >
              <b-card>
                <b-card-title
                  class="my-1"
                >
                  Ordenar por
                </b-card-title>
                <b-card-body>
                  <b-form-select
                    id="sales-order"
                    v-model="salesOrder"
                    :options="salesOrderList"
                  />
                </b-card-body>
              </b-card>
            </b-col>
            <!-- Buscar -->
            <b-col cols="4">
              <b-card>
                <b-card-title
                  class="my-1"
                >
                  Buscar
                </b-card-title>
                <b-card-body>
                  <b-form-group label="ID, nome ou email">
                    <b-form-input v-model="filterSearch" />
                  </b-form-group>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-collapse>
      </b-col>
    </b-row>

    <calendar
      v-if="showCalendar"
      :events="calendarEvents"
      @calendar-event-click="calendarEventClick"
    />

    <b-modal
      ref="modal-card"
      title="Card"
      hide-footer
      size="md"
    >
      <kanban-card
        :data="calendarCard"
        @open-side-bar="openSideBar"
        @kanban-refresh="getCards"
      />
    </b-modal>

    <!-- Processos gerenciais -->
    <b-row
      v-if="selectedRole.role === 'management_processes'"
      class="flex-nowrap"
    >
      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Filtragem ({{ managementProcessesFilterCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in managementProcessesFilterCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        v-if="showManagementProcessesQualification"
        cols="3"
      >
        <h3 class="mb-1">
          Qualificação ({{ serviceQualificationQualificationCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in serviceQualificationQualificationCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Duplos ({{ serviceQualificationDoubleCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in serviceQualificationDoubleCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Ajustes Ativos ({{ serviceQualificationActiveReviewCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in serviceQualificationActiveReviewCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Espertinhos ({{ serviceQualificationSmartiesCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in serviceQualificationSmartiesCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <!--      <b-col-->
      <!--        cols="3"-->
      <!--      >-->
      <!--        <h3 class="mb-1">-->
      <!--          Higienização ({{ kanbanAdditional.length }})-->
      <!--        </h3>-->
      <!--        <b-spinner v-if="firstLoading" />-->
      <!--        <kanban-card-->
      <!--          v-for="card in kanbanAdditional"-->
      <!--          :key="`sanitation-${card.id}`"-->
      <!--          :data="card"-->
      <!--          @open-side-bar="openSideBar"-->
      <!--          @kanban-refresh="getCards"-->
      <!--        />-->

      <!--        <div class="text-center">-->
      <!--          <b-button-->
      <!--            v-if="!isLoading"-->
      <!--            @click="getMoreAdditional(['sanitation'])"-->
      <!--          >-->
      <!--            <span v-if="additionalMoreLoading">Carregando...</span>-->
      <!--            <span v-else>Carregar mais</span>-->
      <!--          </b-button>-->
      <!--        </div>-->
      <!--      </b-col>-->
    </b-row>

    <!-- Sucesso -->
    <b-row
      v-if="(selectedRole.role === 'success' && showSuccessCerc)"
      class="flex-nowrap"
    >
      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          CERC Sucesso
        </h3>
      </b-col>
    </b-row>

    <b-row
      v-if="(selectedRole.role === 'sales' && showSalesCerc)
        || (selectedRole.role === 'success' && showSuccessCerc)
        || (selectedRole.role === 'mentoring' && showMentoringCerc)
        || (selectedRole.role === 'service_qualification' && showServiceQualificationCerc)"
      class="flex-nowrap"
    >
      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Agendamentos ({{ cercCardsScheduled.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in cercCardsScheduled"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Novos Leads ({{ cercCardsNewLead.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in cercCardsNewLead"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Acompanhamento ({{ cercCardsFollowUp.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in cercCardsFollowUp"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Pagamento Pendente ({{ cercCardsAwaitingPayment.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in cercCardsAwaitingPayment"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Minha Carteira ({{ cercCardsClients.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in cercCardsClients"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Potencial Churn ({{ cercCardsChurn.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in cercCardsChurn"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="(selectedRole.role === 'success' && showSuccessKanban)
        || (selectedRole.role === 'mentoring' && showMentoringSuccess)
        || (selectedRole.role === 'service_qualification' && showServiceQualificationSuccessCanalVip)"
      class="flex-nowrap"
    >
      <b-col
        v-if="showOldColumnsBeforeCerc"
        cols="3"
      >
        <h3 class="mb-1">
          Chamado Canal VIP ({{ kanbanAdditionalSuccess.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in kanbanAdditionalSuccess"
          :key="`sanitation-${card.id}`"
          :data="card"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />

        <div class="text-center">
          <b-button
            v-if="!isLoading"
            @click="getMoreAdditional('issue_success')"
          >
            <span v-if="additionalMoreLoading">Carregando...</span>
            <span v-else>Carregar mais</span>
          </b-button>
        </div>
      </b-col>

      <b-col
        v-if="showOldColumnsBeforeCerc"
        cols="3"
      >
        <h3 class="mb-1">
          Qualificados Tour ({{ relationshipScheduleCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in relationshipScheduleCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        v-if="selectedRole.role === 'service_qualification'"
        cols="3"
      >
        <h3 class="mb-1">
          Agendamentos ({{ relationshipOnboardingCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in relationshipOnboardingCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Follow Tour ({{ relationshipFollowUpCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in relationshipFollowUpCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Transição ({{ relationshipTransitionCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in relationshipTransitionCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        v-if="showOldColumnsBeforeCerc"
        cols="3"
      >
        <h3 class="mb-1">
          Apresentação Canal VIP ({{ successNewSubscriberCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in successNewSubscriberCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />

        <div class="text-center">
          <b-button
            v-if="!isLoading"
            @click="getMoreNewSubscriber()"
          >
            <span v-if="additionalMoreLoading">Carregando...</span>
            <span v-else>Carregar mais</span>
          </b-button>
        </div>
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Usuários sem consultor ({{ successUsersWithoutManager.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in successUsersWithoutManager"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        v-if="showOldColumnsBeforeCerc"
        cols="3"
      >
        <h3 class="mb-1">
          Monitoramento ({{ successFollowUpCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in successFollowUpCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Lead VIP ({{ successNoActivePlanCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in successNoActivePlanCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
        <span class="d-block text-center">Exibindo somente <br>os 10 primeiros</span>
      </b-col>

      <b-col
        v-if="showOldColumnsBeforeCerc"
        cols="3"
      >
        <h3 class="mb-1">
          Agendamentos ({{ successFutureSchedulesCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in successFutureSchedulesCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <!--      <b-col-->
      <!--        cols="3"-->
      <!--      >-->
      <!--        <h3 class="mb-1">-->
      <!--          Health Score ({{ successHealthScoreCards.length }})-->
      <!--        </h3>-->
      <!--        <b-spinner v-if="firstLoading" />-->
      <!--        <kanban-card-->
      <!--          v-for="card in successHealthScoreCards"-->
      <!--          :key="card.user_id"-->
      <!--          :data="card"-->
      <!--          :users="cards[0].users"-->
      <!--          @open-side-bar="openSideBar"-->
      <!--          @kanban-refresh="getCards"-->
      <!--        />-->
      <!--      </b-col>-->

    </b-row>

    <!--    Atendimento e Qualificação-->
    <b-row
      v-if="selectedRole.role === 'service_qualification' && showServiceQualificationSuccessAeQ"
      class="flex-nowrap"
    >
      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Qualificação ({{ serviceQualificationQualificationCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in serviceQualificationQualificationCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>
      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Duplos ({{ serviceQualificationDoubleCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in serviceQualificationDoubleCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Ajustes Ativos ({{ serviceQualificationActiveReviewCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in serviceQualificationActiveReviewCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Espertinhos ({{ serviceQualificationSmartiesCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in serviceQualificationSmartiesCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <!--      <b-col-->
      <!--        cols="3"-->
      <!--      >-->
      <!--        <h3 class="mb-1">-->
      <!--          Higienização ({{ kanbanAdditional.length }})-->
      <!--        </h3>-->
      <!--        <b-spinner v-if="firstLoading" />-->
      <!--        <kanban-card-->
      <!--          v-for="card in kanbanAdditional"-->
      <!--          :key="`sanitation-${card.id}`"-->
      <!--          :data="card"-->
      <!--          @open-side-bar="openSideBar"-->
      <!--          @kanban-refresh="getCards"-->
      <!--        />-->

      <!--        <div class="text-center">-->
      <!--          <b-button-->
      <!--            v-if="!isLoading"-->
      <!--            @click="getMoreAdditional(['sanitation'])"-->
      <!--          >-->
      <!--            <span v-if="additionalMoreLoading">Carregando...</span>-->
      <!--            <span v-else>Carregar mais</span>-->
      <!--          </b-button>-->
      <!--        </div>-->
      <!--      </b-col>-->
    </b-row>

    <!-- Mentoria -->
    <b-row
      v-if="selectedRole.role === 'mentoring' && showMentoringKanban"
      class="flex-nowrap"
    >
      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Qualificados ({{ mentoringQualifiedCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in mentoringQualifiedCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Reunião Alinhamento ({{ mentoringMeetCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in mentoringMeetCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Follow Alinhamento ({{ mentoringFollowCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in mentoringFollowCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          QBR Follow Review ({{ mentoringQbrCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in mentoringQbrCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Churn ({{ mentoringChurnCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in mentoringChurnCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>
    </b-row>

    <!--  Vendas SDR -->
    <b-row
      v-if="selectedRole.role === 'sales_sdr'"
      class="flex-nowrap"
    >
      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Novos leads ({{ salesSdrNewLeadsCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in salesSdrNewLeadsCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>
      <b-col
        v-if="showOldColumnsBeforeCerc"
        cols="3"
      >
        <h3 class="mb-1">
          Agendamentos futuros ({{ salesSdrFutureScheduleCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in salesSdrFutureScheduleCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>
      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Finalizados ({{ salesSdrFinishedCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in salesSdrFinishedCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>
    </b-row>

    <!-- Vendas - Pipeline -->
    <b-row
      v-if="selectedRole.role === 'sales' && showSalesPipeline"
      class="flex-nowrap"
    >
      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Novos leads ({{ salesNewLeadsCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in salesNewLeadsCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Qualificação ({{ pipelineQualification.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in pipelineQualification"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Proposta ({{ pipelineProposal.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in pipelineProposal"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Link Enviado ({{ pipelineNegotiation.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in pipelineNegotiation"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>
    </b-row>

    <!--    Vendas -->
    <b-row
      v-if="selectedRole.role === 'sales' && showSalesKanban"
      class="flex-nowrap"
    >
      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Novos leads ({{ salesNewLeadsCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in salesNewLeadsCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />

        <div
          v-if="limitSalesNewLeadsCards"
          class="text-center"
        >
          <b-button
            v-if="!isLoading"
            @click="showAllSalesNewLeads"
          >
            <span v-if="additionalMoreLoading">Carregando...</span>
            <span v-else>Carregar mais</span>
          </b-button>
        </div>
      </b-col>

      <b-col
        v-if="false"
        cols="3"
      >
        <h3 class="mb-1">
          Novos leads SDR ({{ salesNewLeadFromSdrCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in salesNewLeadFromSdrCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Acompanhamento ({{ salesFollowUpCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in salesFollowUpCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />

        <div
          v-if="limitSalesFollowUpCards"
          class="text-center"
        >
          <b-button
            v-if="!isLoading"
            @click="showAllSalesFollowUp"
          >
            <span v-if="additionalMoreLoading">Carregando...</span>
            <span v-else>Carregar mais</span>
          </b-button>
        </div>
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Pagamento pendente ({{ salesOverdueCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in salesOverdueCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        v-if="showOldColumnsBeforeCerc"
        cols="3"
      >
        <h3 class="mb-1">
          Agendamentos futuros ({{ salesFutureSchedulesCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in salesFutureSchedulesCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>
      <b-col
        v-if="showOldColumnsBeforeCerc"
        cols="3"
      >
        <h3 class="mb-1">
          Em negociação ({{ salesNegotiationCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in salesNegotiationCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>
    </b-row>

    <!--    Relationship -->
    <b-row
      v-if="selectedRole.role === 'relationship'"
      class="flex-nowrap"
    >
      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Agendamento ({{ relationshipScheduleCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in relationshipScheduleCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Onboarding ({{ relationshipOnboardingCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in relationshipOnboardingCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Acompanhamento ({{ relationshipFollowUpCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in relationshipFollowUpCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>

      <b-col
        cols="3"
      >
        <h3 class="mb-1">
          Transição ({{ relationshipTransitionCards.length }})
        </h3>
        <b-spinner v-if="firstLoading" />
        <kanban-card
          v-for="card in relationshipTransitionCards"
          :key="card.user_id"
          :data="card"
          :users="cards[0].users"
          @open-side-bar="openSideBar"
          @kanban-refresh="getCards"
        />
      </b-col>
    </b-row>

    <b-sidebar
      id="sidebar"
      ref="sidebar"
      bg-variant="white"
      title="Opções do card"
      width="430px"
      :no-close-on-backdrop="preventCloseSidebar"
      :no-close-on-esc="preventCloseSidebar"
      no-header
      lazy
      right
      backdrop
      shadow
      @change="changeSidebarIsOpen"
    >
      <sidebar
        :card="sidebarCard"
        :user-id="sidebarUserId"
        :show-contact-made="showContactMade"
        @prevent-close-sidebar="changePreventCloseSidebar"
        @kanban-refresh="getCards"
      />
    </b-sidebar>

  </b-container>
</template>

<script>
/* eslint-disable global-require */
import {
  BFormGroup,
  BFormInput,
  BRow,
  BContainer,
  BCol,
  BButton,
  BCard,
  BCardTitle,
  BCardBody,
  BCollapse,
  BFormSelect,
  BFormCheckbox,
  BSpinner,
  BBreadcrumb,
  BSidebar,
  BBadge,
  VBToggle, BModal,
} from 'bootstrap-vue'
import KanbanService from '@/services/kanbanService'
import UserService from '@/services/userService'
import {
  userRoles,
  salesLeadOriginOptions,
  roleSuccessSubscriberOrigins,
  userTypes,
} from '@/utils/options'
import moment from 'moment'
import toasts from '@/mixins/toast'
import Calendar from './Calendar.vue'
import KanbanCard from './Card.vue'
import Sidebar from './Sidebar.vue'
import SalesLeadOrigin from './SalesLeadOrigin.vue'
import SuccessSubscriberOrigin from './SuccessSubscriberOrigin.vue'

export default {
  components: {
    BModal,
    Calendar,
    BBadge,
    BFormGroup,
    BFormInput,
    BRow,
    BContainer,
    BCol,
    BButton,
    BCard,
    BCardTitle,
    BCardBody,
    BCollapse,
    BFormSelect,
    BFormCheckbox,
    KanbanCard,
    BSpinner,
    BBreadcrumb,
    BSidebar,
    SalesLeadOrigin,
    SuccessSubscriberOrigin,
    Sidebar,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  mixins: [toasts],
  data() {
    return {
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Kanban',
          active: true,
        },
      ],
      loadings: {},
      additionalMoreLoading: false,
      additionalPage: 1,
      newSubscriberPage: 1,
      sidebarIsOpen: false,
      kanbanService: null,
      userService: null,
      sidebarCard: null,
      showContactMade: true,
      sidebarUserId: null,
      isLoading: true,
      pauseReload: false,
      firstLoading: true,
      timeToRefresh: 30,
      timeBetweenRefresh: 30,
      preventCloseSidebar: false,
      calendarCard: {},
      showCalendar: false,
      showSalesPipeline: false,
      showSalesKanban: true,
      showSalesCerc: false,
      showMentoringSuccess: false,
      showMentoringKanban: true,
      showMentoringCerc: false,
      showServiceQualificationSuccessAeQ: false,
      showServiceQualificationSuccessCanalVip: false,
      showServiceQualificationCerc: true,
      showSuccessKanban: true,
      showSuccessCerc: false,
      kanbanAdditional: [],
      kanbanAdditionalSuccess: [],
      salesOrder: 'default',
      salesOrderList: [
        {
          text: 'Data de criação do card',
          value: 'default',
        },
        {
          text: 'Lead Score (Maior em cima)',
          value: 'lead-score',
        },
      ],
      cards: [
      ],
      userData: {
      },
      selectedRole: {},
      roles: [],
      interval: null,
      salesLeadOriginOptions,
      roleSuccessSubscriberOrigins,
      userTypes,
      limitSalesNewLeadsCards: true,
      limitSalesFollowUpCards: true,
      filtersSalesCards: {
        sales_lead_origin: salesLeadOriginOptions.reduce((obj, option) => ({ ...obj, [option.value]: true }), {}),
        sales_lead_qualification: ['null', '1', '2', '3', '3+'].reduce((obj, rating) => ({ ...obj, [`${rating}`]: true }), {}),
      },
      filtersSuccessCards: {
        success_subscriber_origin: roleSuccessSubscriberOrigins.reduce((obj, option) => ({
          ...obj,
          [option.value]: true,
        }), {}),
      },
      filterSearch: '',
      filterUserTypes: userTypes.reduce((obj, option) => ({ ...obj, [option.value]: true }), {}),
      showOldColumnsBeforeCerc: false,
    }
  },
  computed: {
    showButtonVip() {
      return this.userData.id !== 328880
    },
    calendarEvents() {
      if (['success', 'mentoring'].includes(this.selectedRole.role)) {
        return [...this.successFutureSchedulesCards, ...this.relationshipOnboardingCards].map(m => {
          const start = m.column === 'future_schedule' ? m.success_new_contact_at : m.schedule_at
          const now = new Date(`${start} GMT-0`)
          now.setMinutes(now.getMinutes() + 15)
          return {
            id: m.user_id,
            title: m.user.name ?? '',
            start,
            end: now.toISOString().substring(0, 19).replace('T', ' '),
            allDay: false,
            card: m,
            backgroundColor: 'bg-light-success',
          }
        })
      }
      return []
    },

    showManagementProcessesQualification() {
      if (this.selectedRole.id === null) {
        return this.managers.filter(f => f.permission_type === 'management_processes' && f.kanban_show_qualification).length > 0
      }

      const manager = this.managers.find(f => f.id === this.selectedRole.id)
      return (manager && manager.kanban_show_qualification)
    },

    filteredCards() {
      return this.cards.filter(card => (
        (
          this.filterSearch.trim() === ''
          || `${card.user.id}`.toLowerCase().includes(this.filterSearch.toLowerCase())
          || card.user.name.toLowerCase().includes(this.filterSearch.toLowerCase())
          || card.user.all_emails.toLowerCase().includes(this.filterSearch.toLowerCase())
        )
        && (
          Object.values(this.filterUserTypes).every(f => f)
          || Object.keys(this.filterUserTypes).filter(f => this.filterUserTypes[f]).includes(card.user.type)
        )
      ))
    },
    cercCardsNewLead() {
      return this.filteredCards.filter(card => (
        card.role === 'cerc' && card.column === 'new_lead' && !this.isHidden(card)
      ))
    },
    cercCardsFollowUp() {
      return this.filteredCards.filter(card => (
        card.role === 'cerc' && card.column === 'follow_up' && !this.isHidden(card)
      ))
    },
    cercCardsAwaitingPayment() {
      return this.filteredCards.filter(card => (
        card.role === 'cerc' && card.column === 'awaiting_payment' && !this.isHidden(card)
      ))
    },
    cercCardsScheduled() {
      return this.filteredCards.filter(card => (
        card.role === 'cerc' && card.column === 'scheduled' && !this.isHidden(card)
      ))
    },
    cercCardsClients() {
      return this.filteredCards.filter(card => (
        card.role === 'cerc' && card.column === 'clients' && !this.isHidden(card)
      ))
    },
    cercCardsChurn() {
      return this.filteredCards.filter(card => (
        card.role === 'cerc' && card.column === 'churn' && !this.isHidden(card)
      ))
    },
    managementProcessesCards() {
      return this.filteredCards.filter(card => (
        card.role === 'management_processes' && !this.isHidden(card)
      ))
    },
    managementProcessesFilterCards() {
      return this.managementProcessesCards.filter(card => card.column === 'filter')
    },
    relationshipCards() {
      return this.filteredCards.filter(card => (
        card.role === 'relationship' && !this.isHidden(card)
      ))
    },
    relationshipTransitionCards() {
      return this.relationshipCards.filter(card => card.column === 'transition')
    },
    relationshipScheduleCards() {
      return this.relationshipCards.filter(card => card.column === 'schedule')
    },
    relationshipOnboardingCards() {
      return this.relationshipCards.filter(card => card.column === 'onboarding')
    },
    relationshipFollowUpCards() {
      return this.relationshipCards.filter(card => card.column === 'follow_up')
        .sort((a, b) => (a.user_id - b.user_id))
    },
    serviceQualificationCards() {
      return this.filteredCards.filter(card => (
        card.role === 'service_qualification'
      ))
    },
    serviceQualificationQualificationCards() {
      return this.serviceQualificationCards.filter(card => (
        card.column === 'qualification'
      ))
        .sort((a, b) => (
          new Date(a.relationship_qualification_at || (new Date() * 10))
          - new Date(b.relationship_qualification_at || (new Date() * 10))))
    },
    serviceQualificationDoubleCards() {
      return this.serviceQualificationCards.filter(card => (
        card.column === 'double'
      ))
        .sort((a, b) => (
          new Date(a.updated_at || (new Date() * 10))
          - new Date(b.updated_at || (new Date() * 10))))
    },
    serviceQualificationActiveReviewCards() {
      return this.serviceQualificationCards.filter(card => (
        card.column === 'active_review'
      ))
        .sort((a, b) => (
          new Date(a.updated_at || (new Date() * 10))
          - new Date(b.updated_at || (new Date() * 10))))
    },
    serviceQualificationSmartiesCards() {
      return this.serviceQualificationCards.filter(card => (
        card.column === 'smarties'
      ))
    },
    successCards() {
      const allFiltersAreChecked = Object.values(this.filtersSuccessCards).every(g => Object.values(g).every(f => f))

      return this.filteredCards.filter(card => (
        card.role === 'success'
        && (
          allFiltersAreChecked || Object.keys(this.filtersSuccessCards).every(field => this.filtersSuccessCards[field][`${card[field]}`])
        )
      ))
    },
    successUsersWithoutManager() {
      return [
        ...this.successNewSubscriberCards,
        ...this.successFollowUpCards,
      ]
    },
    successNewSubscriberCards() {
      return this.successCards.filter(f => f.role === 'success' && f.column === 'new_subscriber' && !this.isHidden(f) && this.hasActivePlan(f) && !this.hasCanceledPlan(f) && !this.hasCanceledUser(f))
        .sort((a, b) => (
          new Date(a.success_new_subscriber_at || (new Date() * 10))
          - new Date(b.success_new_subscriber_at || (new Date() * 10))))
    },
    successFollowUpCards() {
      return this.successCards.filter(f => f.role === 'success' && f.column === 'follow_up' && !this.isHidden(f) && this.hasActivePlan(f) && !this.hasCanceledPlan(f) && !this.hasCanceledUser(f))
        .sort((a, b) => (
          new Date(a.success_new_contact_at || (new Date() * 10))
          - new Date(b.success_new_contact_at || (new Date() * 10))))
        .splice(0, 100)
    },
    successNoActivePlanCards() {
      return this.successCards.filter(f => f.role === 'success' && f.column === 'churn')
        .sort((a, b) => (
          new Date(a.user.quotas?.end_of_plan || 0)
          - new Date(b.user.quotas?.end_of_plan || 0)))
    },
    successHealthScoreCards() {
      return this.successCards.filter(f => f.role === 'success' && f.column === 'health_score' && !this.isHidden(f))
        .sort((a, b) => (
          new Date(a.hide_until)
          - new Date(b.hide_until)))
    },
    successFutureSchedulesCards() {
      return this.successCards.filter(f => f.role === 'success' && f.column === 'future_schedule')
        .sort((a, b) => (
          new Date(a.success_new_contact_at)
          - new Date(b.success_new_contact_at)))
    },
    salesCards() {
      const allFiltersAreChecked = Object.values(this.filtersSalesCards).every(g => Object.values(g).every(f => f))

      return this.filteredCards.filter(card => (
        card.role === 'sales'
        && (
          allFiltersAreChecked || Object.keys(this.filtersSalesCards).every(field => this.filtersSalesCards[field][`${card[field]}`])
        )
      ))
    },

    salesNewLeadsCards() {
      const ret = this.salesCards.filter(f => f.role === 'sales' && f.column === 'new_lead' && !this.isHidden(f))

      if (this.salesOrder === 'lead-score') {
        return ret.sort((a, b) => {
          if (a.lead_score === b.lead_score) {
            return 0
          }

          return a.lead_score < b.lead_score ? 1 : -1
        })
      }

      return ret.sort((a, b) => (
        new Date(a.sales_new_lead_at || (new Date() * 10))
          - new Date(b.sales_new_lead_at || (new Date() * 10))))
        .slice(0, this.limitSalesNewLeadsCards ? Math.min(Math.ceil(ret.length / 2), 100) : ret.length)
    },
    salesNewLeadFromSdrCards() {
      const ret = this.salesCards.filter(f => f.role === 'sales_sdr' && f.column === 'new_lead' && !this.isHidden(f) && f.sales_lead_origin === 'lead_sdr')

      if (this.salesOrder !== 'default') {
        return ret.sort((a, b) => {
          if (a.lead_score === b.lead_score) {
            return 0
          }

          return a.lead_score < b.lead_score ? 1 : -1
        })
      }

      return ret.sort((a, b) => (
        (b.sales_lead_qualification ? parseFloat(b.sales_lead_qualification.replace('+', '.5')) : 4)
          - (a.sales_lead_qualification ? parseFloat(a.sales_lead_qualification.replace('+', '.5')) : 4)
      ))
    },
    mentoringCards() {
      return this.filteredCards
    },
    mentoringQualifiedCards() {
      return this.mentoringCards.filter(f => f.column === 'qualified')
    },
    mentoringMeetCards() {
      return this.mentoringCards.filter(f => f.column === 'meet')
        .sort((a, b) => (
          new Date(a.schedule_at)
              - new Date(b.schedule_at)))
    },
    mentoringFollowCards() {
      return this.mentoringCards.filter(f => f.column === 'follow')
        .sort((a, b) => (
          new Date(a.schedule_at)
              - new Date(b.schedule_at)))
    },
    mentoringQbrCards() {
      return this.mentoringCards.filter(f => f.column === 'qbr')
        .sort((a, b) => (
          new Date(a.schedule_at)
              - new Date(b.schedule_at)))
    },
    mentoringChurnCards() {
      return this.mentoringCards.filter(f => f.column === 'churn')
    },
    salesSdrCards() {
      const allFiltersAreChecked = Object.values(this.filtersSalesCards).every(g => Object.values(g).every(f => f))

      return this.filteredCards.filter(card => (
        card.role === 'sales_sdr'
              && (
                allFiltersAreChecked || Object.keys(this.filtersSalesCards).every(field => this.filtersSalesCards[field][`${card[field]}`])
              )
      ))
    },
    salesSdrNewLeadsCards() {
      return this.salesSdrCards.filter(f => f.column === 'new_lead' && !this.isHidden(f))
        .sort((a, b) => (
          new Date(a.sales_new_lead_at || (new Date() * 10))
                  - new Date(b.sales_new_lead_at || (new Date() * 10))))
    },
    salesSdrFinishedCards() {
      return this.salesSdrCards.filter(f => f.column === 'finished' && !this.isHidden(f))
        .sort((a, b) => (
          new Date(a.sales_new_lead_at || (new Date() * 10))
                  - new Date(b.sales_new_lead_at || (new Date() * 10))))
    },
    salesSdrFutureScheduleCards() {
      return this.salesSdrCards.filter(f => f.column === 'future_schedule')
        .sort((a, b) => (
          new Date(a.hide_until || (new Date() * 10))
                  - new Date(b.hide_until || (new Date() * 10))))
    },
    salesFollowUpCards() {
      const ret = this.salesCards.filter(f => !this.isHidden(f)
        && (
          (
            f.column === 'follow_up'
          ) || (
            f.column === 'following'
            && !f.sales_link_sent_at
          )
        ))

      if (this.salesOrder !== 'default') {
        return ret.sort((a, b) => {
          if (a.lead_score === b.lead_score) {
            return 0
          }

          return a.lead_score < b.lead_score ? 1 : -1
        })
      }

      return ret.sort((a, b) => (
        new Date(a.hide_until || (new Date() * 10))
          - new Date(b.hide_until || (new Date() * 10))))
        .slice(0, this.limitSalesFollowUpCards ? Math.min(Math.ceil(ret.length / 2), 100) : ret.length)
    },
    pipelineBase() {
      return this.salesCards.filter(f => f.column === 'follow_up'
       || f.column === 'following'
       || f.column === 'negotiation')
    },
    pipelineQualification() {
      return this.pipelineBase.filter(f => f.pipeline === 'qualification')
    },
    pipelineProposal() {
      return this.pipelineBase.filter(f => f.pipeline === 'proposal')
    },
    pipelineNegotiation() {
      return this.pipelineBase.filter(f => f.pipeline === 'negotiation')
    },
    salesOverdueCards() {
      return this.salesCards.filter(f => f.column === 'overdue' && !this.isHidden(f))
        .sort((a, b) => (
          new Date(a.sales_link_sent_at || (new Date() * 10))
          - new Date(b.sales_link_sent_at || (new Date() * 10))))
    },
    salesNegotiationCards() {
      return this.salesCards.filter(f => f.column === 'negotiation' && !this.isHidden(f))
        .sort((a, b) => (
          new Date(a.sales_last_contact_at || (new Date() * 10))
          - new Date(b.sales_last_contact_at || (new Date() * 10))))
    },
    salesFutureSchedulesCards() {
      return this.salesCards.filter(this.isHidden)
        .sort((a, b) => (
          new Date(a.hide_until)
          - new Date(b.hide_until)))
    },
  },
  async created() {
    this.kanbanService = new KanbanService()
    this.userService = new UserService()
    const userDataString = await localStorage.getItem('userData')
    this.userData = await JSON.parse(userDataString)

    this.managers = await this.userService.getAndStoreManagers()

    // monta o array com todas as áreas + todos usuários de cada área para exibir no select box
    this.roles = userRoles.map(role => (
      {
        label: role.text,
        options: [
          {
            value: { role: role.value, id: null },
            text: `Todos - ${role.text}`,
          },
          ...this.managers.filter(f => (f.permission_type === role.value && !f.deleted_at)).map(manager => ({
            value: { role: role.value, id: manager.id },
            text: manager.name,
          })),
        ],
      }
    ))

    const userRole = this.roles.find(r => (
      r.options.find(o => (
        o.value.role === this.userData.permission_type
      ))
    ))

    const userOption = userRole ? userRole.options.find(o => o.value.id === this.userData.id) : null

    this.selectedRole = userOption ? userOption.value : {
      role: this.userData.permission_type === 'root' ? 'service_qualification' : this.userData.permission_type,
      id: null,
    }

    this.getCards()

    this.interval = setInterval(() => {
      if (!this.pauseReload && !this.isLoading && !this.preventCloseSidebar && this.$router.currentRoute.name === 'cms-kanban' && !this.sidebarIsOpen) {
        this.timeToRefresh -= 1
        if (this.timeToRefresh < 0) {
          this.timeToRefresh = this.timeBetweenRefresh
          this.getCards()
        }
      }
    }, 1000)
  },
  beforeUnmount() {
    clearInterval(this.interval)
  },
  async beforeDestroy() {
    clearInterval(this.interval)
  },
  methods: {
    showAllSalesNewLeads() {
      this.limitSalesNewLeadsCards = false
    },
    showAllSalesFollowUp() {
      this.limitSalesFollowUpCards = false
    },
    calendarEventClick(event) {
      this.calendarCard = this.calendarEvents.find(f => parseInt(f.id, 10) === parseInt(event.id, 10))?.card
      this.$refs['modal-card'].show()
    },
    toggleMentoring(button) {
      if (button === 'kanban') {
        this.showMentoringKanban = true
        this.showMentoringSuccess = false
        this.showMentoringCerc = false
      } else if (button === 'success') {
        this.showMentoringKanban = false
        this.showMentoringSuccess = true
        this.showMentoringCerc = false
      } else if (button === 'cerc') {
        this.showMentoringKanban = false
        this.showMentoringSuccess = false
        this.showMentoringCerc = true
      }
    },
    toggleSuccessSales(button) {
      if (button === 'kanban') {
        this.showSuccessKanban = true
        this.showSuccessCerc = false
      } else if (button === 'cerc') {
        this.showSuccessKanban = false
        this.showSuccessCerc = true
      }
    },
    toggleServiceQualification(button) {
      if (button === 'a&q') {
        this.showServiceQualificationSuccessAeQ = true
        this.showServiceQualificationSuccessCanalVip = false
        this.showServiceQualificationCerc = false
      } else if (button === 'canal-vip') {
        this.showServiceQualificationSuccessAeQ = false
        this.showServiceQualificationSuccessCanalVip = true
        this.showServiceQualificationCerc = false
      } else if (button === 'cerc') {
        this.showServiceQualificationSuccessAeQ = false
        this.showServiceQualificationSuccessCanalVip = false
        this.showServiceQualificationCerc = true
      }
    },
    toggleKanbanSales(button) {
      if (button === 'pipeline') {
        this.showSalesPipeline = true
        this.showSalesKanban = false
        this.showSalesCerc = false
      } else if (button === 'kanban') {
        this.showSalesPipeline = false
        this.showSalesKanban = true
        this.showSalesCerc = false
      } else if (button === 'cerc') {
        this.showSalesPipeline = false
        this.showSalesKanban = false
        this.showSalesCerc = true
      }
    },
    changeRole() {
      this.cards = []
      this.getCards()
    },
    changePreventCloseSidebar(event) {
      this.preventCloseSidebar = event
    },
    isHidden(kanban) {
      return kanban.hide_until && moment().isBefore(moment(String(kanban.hide_until)).startOf('day'))
    },
    hasCanceledPlan(kanban) {
      return kanban.user.quotas?.canceled_at !== null
    },
    hasCanceledUser(kanban) {
      return kanban.user.canceled_at !== null
    },
    hasNoActivePlanForAtLeastFiveDays(kanban) {
      const today = new Date()

      const fiveDaysAgo = new Date()
      fiveDaysAgo.setDate(today.getDate() - 5)

      return (
        kanban.user.quotas?.end_of_plan
        && (new Date(kanban.user.quotas?.end_of_plan)) < fiveDaysAgo
      )
    },
    hasActivePlan(kanban) {
      return new Date() < new Date(kanban.user.quotas?.end_of_plan || 0)
    },

    changeSidebarIsOpen(event) {
      this.sidebarIsOpen = event
    },

    openSideBar(params) {
      this.$root.$emit('bv::toggle::collapse', 'sidebar')
      this.sidebarCard = params
      this.$refs['modal-card'].hide()
      // this.sidebarUserId = params.userId
      // this.showContactMade = params.showContactMade
    },

    getSmarties() {
      const now = new Date()
      const lastWeek = new Date()
      lastWeek.setDate(now.getDate() - 7)
      now.setDate(now.getDate() + 1)
      return this.userService.listSmarties(
        `${lastWeek.getFullYear()}-${String(lastWeek.getMonth() + 1).padStart(2, '0')}-${String(lastWeek.getDate()).padStart(2, '0')}`,
        `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`,
        1,
      )
        .then(r => r.data.data.users.data.map(s => ({
          column: 'smarties',
          role: 'service_qualification',
          user: s,
          user_id: s.id,
        })))
    },

    getMoreNewSubscriber() {
      this.newSubscriberPage += 1
      this.additionalMoreLoading = true
      this.pauseReload = true

      this.toastInfo('Alerta!', 'O recarregamento automático foi interrompido')

      this.kanbanService.listByRole(this.selectedRole.role, this.selectedRole.id ?? 0, 'new_subscriber', this.newSubscriberPage).then(response => {
        this.cards = [
          ...this.cards,
          ...response.data.data.kanbans,
        ]
      }).finally(() => {
        this.additionalMoreLoading = false
      })
    },

    getMoreAdditional(columns) {
      this.additionalPage += 1
      this.additionalMoreLoading = true
      this.pauseReload = true

      this.toastInfo('Alerta!', 'O recarregamento automático foi interrompido')

      return this.kanbanService.listAdditional(columns, this.selectedRole.id ?? 0, this.additionalPage).then(response => {
        this.kanbanAdditional = [
          ...this.kanbanAdditional,
          ...response.data.data.kanbans.map(m => ({
            ...m,
            additional: true,
          })),
        ]
      }).finally(() => {
        this.additionalMoreLoading = false
      })
    },

    async getAdditional(columns) {
      return this.kanbanService.listAdditional(columns, this.selectedRole.id ?? 0).then(response => response.data.data.kanbans.map(m => ({
        ...m,
        additional: true,
      })))
    },

    getLoading(stringVariables, value) {
      const list = typeof stringVariables === 'string' ? [stringVariables] : stringVariables

      const { loadings } = this
      list.forEach(stringVariable => {
        if (!loadings[stringVariable]) {
          loadings[stringVariable] = 0
        }

        if (value) {
          loadings[stringVariable] += 1
        } else if (loadings[stringVariable] > 0) {
          loadings[stringVariable] -= 1
        }

        if (loadings[stringVariable] > 0) {
          this[stringVariable] = true
        } else {
          this[stringVariable] = false
        }
      })
    },

    async getCards() {
      this.timeToRefresh = this.timeBetweenRefresh
      this.pauseReload = false
      this.additionalPage = 1
      this.newSubscriberPage = 1

      const loadingList = this.firstLoading ? ['isLoading', 'firstLoading'] : ['isLoading']
      this.firstLoading = false

      if (['service_qualification', 'management_processes'].includes(this.selectedRole.role)) {
        // this.getLoading(loadingList, true)
        // this.getAdditional(['sanitation']).then(response => {
        //   this.kanbanAdditional = response
        // }).finally(() => {
        //   this.getLoading(loadingList, false)
        // })

        this.getLoading(loadingList, true)
        this.getAdditional(['issue_success']).then(response => {
          this.kanbanAdditionalSuccess = response
        }).finally(() => {
          this.getLoading(loadingList, false)
        })
      }

      this.getLoading(loadingList, true)
      this.kanbanService.listByRole(this.selectedRole.role, this.selectedRole.id ?? 0).then(response => {
        if (response.status === 200) {
          this.cards = [
            ...this.cards.filter(f => f.column === 'smarties'),
            ...response.data.data.kanbans,
          ]
        }
        return []
      }).finally(() => {
        this.getLoading(loadingList, false)
      })

      if (['service_qualification', 'management_processes'].includes(this.selectedRole.role)) {
        this.getLoading(loadingList, true)
        this.getSmarties().then(response => {
          this.cards = [
            ...this.cards.filter(f => f.column !== 'smarties'),
            ...response,
          ]
        }).finally(() => {
          this.getLoading(loadingList, false)
        })
      }
    },

    toggleAll(filterGroup, filter) {
      if (!filter) {
        const areAllChecked = Object.values(this[filterGroup]).every(e => e)
        Object.keys(this[filterGroup]).forEach(fe => { this[filterGroup][fe] = !areAllChecked })
        return
      }

      const areAllChecked = Object.values(filterGroup[filter]).every(e => e)
      this.$set(
        filterGroup,
        filter,
        Object.keys(filterGroup[filter]).reduce((r, origin) => ({
          ...r,
          [origin]: !areAllChecked,
        }), {}),
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';

.paused {
  font-weight: bold;
  font-family: math;
}

.events {
  border: 0px none;
  color: #FFFFFF!important;
}

.event-green {

}

.event-red {

}

.time-to-refresh {
  width: 75px
}
</style>
