<template>
  <div>
    <b-card
      :bg-variant="cardVariant ? `light-${cardVariant}` : null"
      :body-class="`p-0 ${cardVariant ? 'text-body' : ''}`"
      class="mb-2 kanban-card"
      @mouseover="mouseOver = true"
      @mouseout="mouseOver = false"
      @click="$emit('open-side-bar', data)"
    >
      <b-overlay
        :show="isLoading"
        rounded="sm"
      >
        <b-card-header>
          <div class="left">
            <b-badge
              pill
              :variant="data.user.type === 'company' ? 'light-warning' : 'light-info'"
            >{{ $t(`users.columns.type.${data.user.type}`) }}</b-badge>
            <b-badge
              v-if="data.migrated"
              pill
              variant="danger"
              class="ml-1"
            >
              Migrado
            </b-badge>
          </div>

          <div
            v-if="
              $can('move_card', 'Kanban')
                && !(
                  data.role === 'service_qualification'
                  && data.column === 'smarties'
                )
            "
            class="menu"
          >
            <!-- Relacionamento -->
            <b-dropdown
              id="dropdown-1"
              no-caret
              variant="link"
              text="..."
              right
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="moveCardTo"
              >
                Mover Card
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <span
            v-if="data.role === 'sales' && data.sales_lead_qualification && data.sales_lead_origin !== 'lead_sdr'"
            right
            class="mr-2"
          >
            <feather-icon
              v-for="star in parseInt(data.sales_lead_qualification.replace(/[^0-9]/g, ''), 10)"
              :key="star"
              icon="StarIcon"
              fill="yellow"
              stroke="gold"
            />
          </span>

          <span
            v-if="data.role === 'sales' && data.sales_lead_qualification && data.sales_lead_origin === 'lead_sdr'"
            right
            class="mr-2"
          >
            {{ data.sales_lead_qualification }}
          </span>
        </b-card-header>

        <b-card-body>
          <div v-if="data.column === 'onboarding'">
            <b-badge
              class="mb-1"
              pill
              variant="light-success"
            >
              {{ data.schedule_tag === 'presentation' ? 'Canal VIP' : 'Tour Plataforma' }}
            </b-badge>
          </div>

          <div v-if="data.role === 'relationship' && data.column === 'follow_up'">
            <b-badge
              pill
              variant="light-info"
              class="mb-1"
            >
              <feather-icon
                icon="RotateCcwIcon"
                size="16"
              />
              {{ countWorkingDays(data.relationship_following_at) }}º check de utilização
            </b-badge>
          </div>

          <div
            v-if="data.additional && data.column === 'issue_success'"
          >
            <span>Criado em:</span>
            <div
              class="d-flex align-items-center mb-1"
            >
              <feather-icon
                icon="CalendarIcon"
                size="16"
                class="text-success"
              />
              <div class="card-date-right">
                <span>{{ data.created_at | date('DD/MM H:mm') }}</span>
                <small> ({{ fromNow(data.created_at) }})</small>
              </div>
            </div>
          </div>

          <div v-if="data.additional && data.column === 'sanitation'">
            <b-badge
              pill
              variant="secondary"
              class="mb-1"
            >
              <Iconify
                :icon="icons.broom20Filled"
                width="30"
                height="30"
              />
              {{ $t(`kanban-additional.tag.${data.tag}`) }}
            </b-badge>
          </div>

          <div v-if="data.role === 'sales' && data.lead_score !== null">
            <b-badge
              v-b-tooltip.hover.html="userLeadScore(data)"
              pill
              variant="secondary"
              class="mb-1"
            >
              <Iconify
                :icon="icons.leadsIcon"
                width="30"
                height="30"
              />
              Lead score {{ data.lead_score }}
            </b-badge>
          </div>

          <div v-if="data.pipeline && data.pipeline && data.role !== 'cerc'">
            <b-badge
              pill
              :variant="pipelineColors[data.pipeline]"
              class="mb-1"
            >
              <Iconify
                :icon="icons.pipeIcon"
                width="30"
                height="30"
              />
              {{ $t(`kanban.pipeline.${data.pipeline}`) }}
            </b-badge>
          </div>

          <div
            v-if="data.role === 'sales' && data.sales_lead_origin"
          >
            <sales-lead-origin
              v-if="data.sales_lead_origin"
              :card="data"
              class="mb-1"
            />
            <b-alert
              v-if="data.user.settings.status_reason === 'double' && !data.user.settings.double_user_id"
              show
              variant="danger"
              class="mb-1 p-1"
            >
              <b-link
                :to="{ name: 'cms-user-form-update', params: { id: data.user.id } }"
                target="_blank"
                @click="openProfile"
              >
                Não foi encontrado ID do usuário original nos dados cadastrais
                <feather-icon
                  icon="ExternalLinkIcon"
                  size="14"
                />
              </b-link>
            </b-alert>
          </div>

          <div
            v-if="['sales', 'sales_sdr'].includes(data.role) && data.hide_until"
            class="d-flex align-items-center mb-1"
          >
            <feather-icon
              icon="CalendarIcon"
              size="16"
              :class="{'text-warning': isHidden(data), 'text-success': !isHidden(data) }"
            />
            <div class="card-date-right">
              <span>{{ data.hide_until | date('DD/MM H:mm') }}</span>
              <small> (Agendado)</small>
            </div>
          </div>

          <div
            v-if="data.role === 'sales'
              && salesDaysUntilFinishedNoContact(data) <= 10
            "
          >
            <div class="d-flex align-items-center mb-1">
              <feather-icon
                icon="CalendarIcon"
                size="16"
                :class="{'text-danger': salesDaysUntilFinishedNoContact(data) <= 5, 'text-warning': salesDaysUntilFinishedNoContact(data) > 5 }"
              />
              <div class="card-date-right">
                <span>{{ salesDaysUntilFinishedNoContact(data) }}</span>
                <small> (Dia<span v-if="salesDaysUntilFinishedNoContact(data) != 1">s</span> para finalização)</small>
              </div>
            </div>
          </div>

          <div
            v-if="data.role === 'success'"
          >
            <success-subscriber-origin
              v-if="data.success_subscriber_origin"
              :card="data"
              class="mb-1"
            />
            <div
              v-if="data.hide_until || data.success_new_contact_at"
              class="d-flex align-items-center mb-1"
            >
              <feather-icon
                icon="CalendarIcon"
                size="16"
                :class="{'text-success': isHidden(data) || isNewContact(data), 'text-danger': !isHidden(data) && !isNewContact(data) }"
              />
              <div class="card-date-right">
                <span v-if="data.hide_until">{{ data.hide_until | date('DD/MM H:mm') }}</span>
                <span v-if="data.success_new_contact_at">{{ data.success_new_contact_at | date('DD/MM H:mm') }}</span>
                <small> (Agendado)</small>
              </div>
            </div>
            <div
              v-if="!hasActivePlan(data)"
            >
              <div class="d-flex align-items-center mb-1">
                <feather-icon
                  icon="CalendarIcon"
                  size="16"
                  class="text-danger"
                />
                <div class="card-date-right">
                  <span>{{ data.user.quotas.end_of_plan | date('DD/MM H:mm') }}</span>
                  <small> (Fim da assinatura)</small>
                </div>
              </div>
              <b-alert
                variant="danger"
                show
                class="p-1"
              >
                Será transferido para Vendas em <span class="text-danger">{{ successDaysUntilTransferToSalesKanban(data) }}</span> dia<span v-if="Math.abs(successDaysUntilTransferToSalesKanban(data)) !== 1">s</span>
              </b-alert>
            </div>
          </div>

          <div
            v-if="data.column === 'qualification'"
            class="d-flex align-items-center mb-1"
          >
            <feather-icon
              icon="CalendarIcon"
              size="16"
              :class="{'text-danger': isExpired(data.relationship_qualification_at, -1), 'text-success': !isExpired(data.relationship_qualification_at, -1) }"
            />
            <div class="card-date-right">
              <span>{{ data.relationship_qualification_at | date('DD/MM H:mm') }}</span>
              <small> (Aprovado em)</small>
            </div>
          </div>

          <div
            v-if="data.column === 'schedule'"
            class="d-flex align-items-center mb-1"
          >
            <feather-icon
              icon="CalendarIcon"
              size="16"
              class="text-success"
            />
            <div class="card-date-right">
              <span>{{ data.relationship_schedule_at | date('DD/MM H:mm') }}</span>
              <small> (Qualificado em)</small>
            </div>
          </div>

          <div
            v-if="data.column === 'onboarding' || (data.role === 'mentoring' && ['meet', 'follow', 'qbr'].includes(data.column))"
            class="d-flex align-items-center mb-1"
          >
            <feather-icon
              icon="CalendarIcon"
              size="16"
              :class="{'text-danger': isExpired(data.schedule_at), 'text-success': !isExpired(data.schedule_at) }"
            />
            <div class="card-date-right">
              <span>{{ data.schedule_at | date('DD/MM H:mm') }}</span>
              <small> ({{ data.schedule_type }})</small>
            </div>
          </div>

          <div
            v-if="data.role === 'cerc' && ['scheduled'].includes(data.column)"
            class="d-flex align-items-center mb-1"
          >
            <feather-icon
              icon="CalendarIcon"
              size="16"
              :class="{'text-danger': isExpired(data.cerc_schedule_at), 'text-success': !isExpired(data.cerc_schedule_at) }"
            />
            <div class="card-date-right">
              <span>{{ data.cerc_schedule_at | date('DD/MM H:mm') }}</span>
              <small> ({{ data.cerc_schedule_type }})</small>
            </div>
          </div>

          <div v-if="data.role === 'service_qualification' && data.column === 'smarties' && data.user.name">
            <!-- eslint-disable vue/no-v-html -->
            <span v-html="SmartiesSearch.methods.bold(data.user.name)" />
            <!-- eslint-enable vue/no-v-html -->
          </div>
          <div v-else>
            <p>
              <span>{{ data.user.name }}</span>
              <br>
              <span v-if="data.user.type === 'company'">{{ data.user.profile.company_name }}</span>
              <span v-else>{{ !data.user.profile ? '' : data.user.profile.company_constituted }}</span>
            </p>
          </div>

          <p>{{ data.user.email }}</p>

          <div
            v-if="userPhones.length > 0"
            @click.stop
          >
            <b-input-group
              v-for="(phone, index) in userPhones"
              :key="`${data.user.id}-${index}-${phone}`"
              size="sm"
            >
              <b-form-input
                :value="phone"
                class="form-control font-small-4"
                readonly
              />
              <b-input-group-append
                v-if="shouldShowCallButton"
              >
                <b-button
                  v-b-tooltip.hover.noninteractive="'Iniciar ligação com VOIP'"
                  variant="outline-primary"
                  :href="`callto:0${phone.match(/[0-9]*/g).join('')}`"
                  class="d-flex"
                  :style="{ placeItems: 'center' }"
                >
                  <feather-icon icon="PhoneCallIcon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>

          <div v-if="data.description && data.description !== ''">
            <label>Descrição:</label>
            <p style="white-space: pre-wrap">
              {{ data.description }}
            </p>
            <b-button
              v-if="openDoubleProfileId"
              :to="{ name: 'cms-user-form-update', params: { id: openDoubleProfileId } }"
              target="_blank"
              variant="gradient-primary"
              @click="openProfile"
            >
              Abrir duplo
            </b-button>
          </div>
          <div v-if="data.role === 'service_qualification' && data.column === 'smarties' && data.user.presentation">
            <label>Apresentação:</label>
            <!-- eslint-disable vue/no-v-html -->
            <p v-html="SmartiesSearch.methods.bold(data.user.presentation)" />
            <!-- eslint-enable vue/no-v-html -->
          </div>
        </b-card-body>

        <b-card-footer class="d-flex justify-content-between align-items-start">
          <div>
            <small
              v-if="data.role === 'success' && data.success_new_subscriber_at"
            >
              <label>Card criado em:</label><br>
              {{ data.success_new_subscriber_at | date('DD/MM H:mm') }}
            </small>
            <small
              v-if="data.role === 'sales' && data.sales_new_lead_at"
            >
              <label>Card criado em:</label><br>
              {{ data.sales_new_lead_at | date('DD/MM H:mm') }}
            </small>
          </div>
          <div v-if="(['root', 'service_qualification'].includes(userData.permission_type)) && manager">
            <b-badge
              pill
              :variant="userData.id === data.account_manager_id ? 'light-success' : 'light-info'"
            >
              {{ manager.name }}
            </b-badge>
          </div>
          <b-dropdown
            v-if="data.role === 'service_qualification' && data.column === 'double'"
            variant="gradient-primary"
            toggle-class="btn-icon rounded-circle"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="UserIcon"
                size="16"
                class="user-icon align-middle"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'cms-user-form-update', params: { id: data.user.id } }"
              target="_blank"
              @click="openProfile"
            >
              Duplo
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'cms-user-form-update', params: { id: data.user.settings.double_user_id } }"
              target="_blank"
              @click="openProfile"
            >
              Original
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            v-else-if="data.additional && data.column === 'sanitation'"
            variant="gradient-primary"
            toggle-class="btn-icon rounded-circle"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="UserIcon"
                size="16"
                class="user-icon align-middle"
              />
            </template>

            <b-dropdown-item
              :to="{ name: 'cms-user-form-update', params: { id: data.user_origin_id } }"
              target="_blank"
              @click="openProfile"
            >
              Remetente
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'cms-user-form-update', params: { id: data.user_id } }"
              target="_blank"
              @click="openProfile"
            >
              Destinatário
            </b-dropdown-item>
          </b-dropdown>
          <b-button
            v-else
            variant="gradient-primary"
            class="btn-icon rounded-circle"
            :to="{ name: 'cms-user-form-update', params: { id: data.user.id } }"
            target="_blank"
            @click="openProfile"
          >
            <feather-icon
              icon="UserIcon"
              size="16"
              class="user-icon align-middle"
            />
          </b-button>
        </b-card-footer>
      </b-overlay>
    </b-card>

    <b-modal
      ref="move-card-to-modal"
      hide-footer
      size="md"
      title="Mover Card"
    >
      <move-card-modal
        :data="data"
        @reload="reloadKanban"
      />
    </b-modal>
  </div>

</template>

<script>
/* eslint-disable global-require */
import {
  BOverlay,
  BCard,
  BCardHeader,
  BCardBody,
  BCardFooter,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
  BAlert,
  BLink,
  VBTooltip,
} from 'bootstrap-vue'
import moment from 'moment'
import KanbanService from '@/services/kanbanService'
import UserService from '@/services/userService'
import broom20Filled from '@iconify/icons-fluent/broom-20-filled'
import pipeIcon from '@iconify/icons-mdi/pipe'
import leadsIcon from '@iconify/icons-mdi/leads'
import SalesLeadOrigin from './SalesLeadOrigin.vue'
import SuccessSubscriberOrigin from './SuccessSubscriberOrigin.vue'
import MoveCardModal from './MoveCard.vue'
import SmartiesSearch from '../tools/smarties/Search.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BOverlay,
    BCard,
    BCardBody,
    BCardHeader,
    BCardFooter,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BLink,
    SalesLeadOrigin,
    SuccessSubscriberOrigin,
    MoveCardModal,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      SmartiesSearch,

      kanbanService: null,
      userService: null,
      mouseOver: false,
      dateNtim: null,
      isLoading: false,
      modalScheduleTitle: '',
      userData: {},

      managers: [],
      icons: {
        pipeIcon,
        broom20Filled,
        leadsIcon,
      },
      pipelineColors: {
        new_lead: 'secondary',
        qualification: 'alert',
        proposal: 'warning',
        negotiation: 'danger',
      },
    }
  },
  computed: {
    shouldShowCallButton() {
      if (
        ['service_qualification', 'management_processes'].includes(this.data.role)
        && !(this.data.additional && this.data.column === 'sanitation')
      ) {
        return false
      }

      return true
    },
    userPhones() {
      return [this.data.user.cell_phone ?? '', ...this.data.user.phones ?? ''].filter(f => f)
    },
    manager() {
      return this.managers.find(f => f.id === this.data.account_manager_id)
    },
    cardVariant() {
      if (
        // Cards de vendas
        this.data.role === 'sales'
        //  nas colunas de acompanhamento
        && this.data.column === 'follow_up'
        //  com data de agendamento
        && this.data.hide_until
        //  para antes do dia corrente
        && moment(String(this.data.hide_until)).isBefore(moment().startOf('day'))
      ) {
        return 'danger'
      }

      if (
        // Cards de SDR
        this.data.role === 'sales_sdr'
        //  nas colunas de agendamento futuro
        && this.data.column === 'future_schedule'
        //  com data de agendamento
        && this.data.hide_until
      ) {
        const scheduleDate = moment(String(this.data.hide_until))

        if (
          //  para antes do dia corrente
          scheduleDate.isBefore(moment().startOf('day'))
        ) {
          return 'danger'
        }

        if (
          //  para o dia corrente
          scheduleDate.isSame(moment(), 'day')
        ) {
          return 'warning'
        }
      }

      return null
    },
    openDoubleProfileId() {
      const doubleProfileId = this.data.description.match(/duplo:.*(\b\d+\b)/i)

      return doubleProfileId && doubleProfileId[1]
    },
  },
  async created() {
    this.kanbanService = new KanbanService()
    this.userService = new UserService()
    this.managers = await this.userService.getAndStoreManagers()
    const userDataString = await localStorage.getItem('userData')
    this.userData = await JSON.parse(userDataString)
  },
  methods: {
    fromNow(date) {
      return moment(date).fromNow()
    },
    userLeadScore(user) {
      let ret = '<p>'
      ret += `<span class="font-weight-bold">${user.lead_score_plans}</span> - <span>Plano</span><br/>`
      ret += `<span class="font-weight-bold">${user.lead_score_payment_links}</span> - <span>Link de pagamento</span><br/>`
      ret += `<span class="font-weight-bold">${user.lead_score_recent_usage}</span> - <span>Uso recente</span><br/>`
      ret += `<span class="font-weight-bold">${user.lead_score_recurrence_trigger}</span> - <span>Reincidência de gatilhos(página de plano, página de pagamento, enviar mensagem sem quota)</span><br/>`
      ret += `<span class="font-weight-bold">${user.lead_score_msg_read}</span> - <span>Leitura de mensagem</span><br/>`
      ret += `<span class="font-weight-bold">${user.lead_score_type}</span> - <span>É empresa</span><br/>`
      ret += `<span class="font-weight-bold">${user.lead_score_msg_sent_or_viewed_phone}</span> - <span>Mensagem enviada ou Visualização de telefone</span><br/>`
      ret += `<span class="font-weight-bold">${user.lead_score_email_read}</span> - <span>Leitura de e-mail</span><br/>`
      ret += `<span class="font-weight-bold">${user.lead_score_trigger_plans}</span> - <span>Acesso a página de planos</span><br/>`
      ret += '</p>'
      return ret
    },
    moveCardTo(event) {
      event.stopPropagation()
      this.$refs['move-card-to-modal'].show()
    },

    countWorkingDays(_dt) {
      const dt = new Date(_dt)
      const now = new Date()

      let days = 0
      if (now > dt) {
        const diff = Math.floor((now.getTime() - dt.getTime()) / (1000 * 3600 * 24))

        // eslint-disable-next-line no-plusplus
        for (let i = 1; i <= diff; i++) {
          const day = new Date()
          day.setDate(day.getDate() - i)
          if (day.getDay() > 0 && day.getDay() < 6) {
            days += 1
          }
        }
      }

      if (days > 3) {
        return 2
      }

      return 1
    },

    openProfile(event) {
      event.stopPropagation()
    },

    isExpired(scheduleAt, days) {
      const addDays = (days) || 0

      const now = new Date()
      const dateToCompare = new Date()
      dateToCompare.setDate(now.getDate() + addDays)
      const schedule = new Date(scheduleAt)

      return dateToCompare.getTime() > schedule.getTime()
    },

    isHidden(kanban) {
      return kanban.hide_until && (new Date(kanban.hide_until)) > (new Date())
    },

    isNewContact(kanban) {
      return kanban.success_new_contact_at && (new Date(kanban.success_new_contact_at)) > (new Date())
    },

    hasActivePlan(kanban) {
      return new Date() < new Date(kanban.user.quotas?.end_of_plan || 0)
    },

    successDaysUntilTransferToSalesKanban(kanban) {
      return 15 - parseInt((new Date().getTime() - new Date(kanban.user.quotas.end_of_plan).getTime()) / (1000 * 60 * 60 * 24), 10)
    },

    salesDaysUntilFinishedNoContact(kanban) {
      return 20 - parseInt((new Date().getTime() - new Date(Math.max(
        new Date(kanban.sales_new_lead_at || 0).getTime(),
        new Date(kanban.hide_until || 0).getTime(),
        new Date(kanban.sales_last_contact_at || 0).getTime(),
      )).getTime()) / (1000 * 60 * 60 * 24), 10)
    },

    reloadKanban() {
      this.$emit('kanban-refresh')
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/pages/page-misc.scss';

  .badge.badge-alert {
    background-color: #dcad3e;
  }

  .tooltip-inner {
    p {
      text-align: left;
    }
  }

  .numInput{
    pointer-events: none;
  }
</style>
